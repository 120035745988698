<template>
    <div class="pb-5">
        <v-title title="Roles - Add"></v-title>
        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Add role</h1>
                </div>
            </div>
        </title-box>

        <form @submit.prevent="submitForm">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-7">
                        <mercur-card class="mt-n4 mb-4">
                            <mercur-input v-model="form.roleName" :class="getValidationClass($v, 'roleName')">
                                Role name
                                <template #note>
                                    <span class="form-error" v-if="!$v.form.roleName.required">Role name is required</span>
                                </template>
                            </mercur-input>
                        </mercur-card>
                        <div class="row">
                            <div class="col-6">
                                <mercur-card class="mb-4 h-100">
                                    <h2 class="mt-1 font-weight-normal">Permissions</h2>
                                    <table class="table table-bordered table-hover">
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Permission override</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="permission in items" :key="permission.permissionId">
                                            <td>{{ permission.permissionName }}</td>
                                            <td>
                                                <mercur-checkbox v-model="permission.permissionStatus" :id="`permission-${permission.permissionId}`">Is allowed</mercur-checkbox>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </mercur-card>
                            </div>
                            <div class="col-6">
                                <mercur-card class="mb-4 h-100">
                                    <h2 class="mt-1 font-weight-normal">Groups</h2>
                                    <table class="table table-bordered table-hover">
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Group override</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="group in groups" :key="group.groupId">
                                            <td>{{ group.groupName }}</td>
                                            <td>
                                                <mercur-checkbox v-model="group.groupStatus" :id="`group-${group.groupId}`">Is allowed</mercur-checkbox>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </mercur-card>
                            </div>
                        </div>
                        <div class="text-right mt-4">
                            <mercur-button class="btn" to="/roles">Cancel</mercur-button>
                            <mercur-button class="btn btn-primary" type="submit" :disabled="loading">Add role</mercur-button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import ResourcesView from '@/views/ResourcesView'
import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'AddRole',
    mixins: [ResourcesView, FormMixin],
    data () {
        return {
            url: CONFIG.API.ROUTES.PERMISSIONS.OVERVIEW,
            action: CONFIG.API.ROUTES.ROLES.ADD,
            form: {
                permissions: {},
                groups: {},
            },
            groups: [],
            isNotApplicationBound: true,
        }
    },
    validations: {
        form: {
            roleName: {
                required,
            },
        },
    },
    computed: {
        rolePermissions () {
            let arr = []
            this.items.forEach((item) => {
                if (item.permissionStatus) {
                    arr.push(item.permissionId)
                }
            })
            return arr
        },
        roleGroups () {
            let arr = []
            this.groups.forEach((item) => {
                if (item.groupStatus) {
                    arr.push(item.groupId)
                }
            })
            return arr
        },
    },
    methods: {
        submitForm () {
            this.form.permissions.permissionIds = this.rolePermissions
            this.form.groups.groupIds = this.roleGroups

            this.submit(this.action, this.form).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Role was added',
                })
                setTimeout(() => {
                    this.$router.push({
                        name: 'RolesWrapper',
                    })
                }, 1000)
            })
        },
    },
    created () {
        const url = CONFIG.API.ROUTES.APPLICATIONS_GROUPS.OVERVIEW
        this.addJob(url)
        this.$api.get(url).then(({ data }) => {
            this.groups = data['data']
        }).catch(err => {
            if (CONFIG.DEBUG) {
                console.log(err)
            }
        }).finally(() => {
            this.finishJob(url)
            this.isFetchingResources = false
        })
    },
}
</script>
